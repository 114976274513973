export const STORE_PREFIX = process.env.NEXT_PUBLIC_STORE_PREFIX;
export const STORE_ID = process.env.NEXT_PUBLIC_STORE_ID;
export const RAZORPAY_KEY = process.env.NEXT_PUBLIC_RAZORPAY_KEY;
export const RAZORPAY_SCRIPT = "https://checkout.razorpay.com/v1/checkout.js";
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const MEDIA_BASE_URL = process.env.NEXT_PUBLIC_MEDIA_URL;
export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
export const HOTJAR_SNIPPET_VERSION =
  process.env.NEXT_PUBLIC_HOTJAR_SNIPPET_VERSION;
export const OPTIMONK_ID = process.env.NEXT_PUBLIC_OPTIMONK_ID;
export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
export const CUSTOMER_GLU_KEY = process.env.NEXT_PUBLIC_CUSTOMER_GLU_KEY;
export const WISEPOPS_KEY = process.env.NEXT_PUBLIC_WISEPOPS_KEY;
export const GOOGLE_VERIFICATION_TAG =
  process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_TAG;
export const AWS_CLIENT_ID = process.env.NEXT_PUBLIC_AWS_CLIENT_ID;
export const AFFISE_ENABLED = process.env.NEXT_PUBLIC_AFFISE_ENABLED;
