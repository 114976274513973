import React from "react";
import Head from "next/head";

function NextHead({
  siteName,
  title,
  description,
  keywords,
  image,
  canonical,
  googleVerificationTag,
}) {
  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover, user-scalable=no, shrink-to-fit=no"
      />
      <meta name="HandheldFriendly" content="true" />
      {!!googleVerificationTag && (
        <meta name="google-site-verification" content={googleVerificationTag} />
      )}

      <link rel="canonical" href={canonical} />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords || siteName} />

      <meta property="og:site_name" content={siteName} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:description" content={description} key="ogdesc" />
      <meta property="og:url" content={canonical} />
      {!!image && <meta property="og:image" content={image} />}

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {!!image && <meta property="twitter:image" content={image} />}
    </Head>
  );
}

export default NextHead;
