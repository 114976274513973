import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { MagnifyingGlass, Search } from "~/components/icons";
import { eventActions } from "~/store/events";

const TYPING_SPEED = 100;
const DELETING_SPEED = 30;
const dataText = [
  "Serum",
  "Moisturizer",
  "Cleanser",
  "Face Oil",
  "Sunscreen",
  "Acne",
  "Dull Skin",
  "Dry Skin",
];

function SearchForm({ type = "input", defaultSearch = "", productSearched }) {
  const router = useRouter();
  const [search, setSearch] = useState(defaultSearch);
  const [timer, setTimer] = useState(null);
  const [text, setText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(TYPING_SPEED);
  const dispatch = useDispatch();

  const { pathname } = router;

  useEffect(() => {
    document.querySelector("body").addEventListener("click", onBodyClick);

    return () => {
      document.querySelector("body").removeEventListener("click", onBodyClick);
    };
  }, []);

  useEffect(() => {
    setSearch(defaultSearch);
  }, [router.query.slug]);

  useEffect(() => {
    if (search.length > 2) {
      if (timer) clearTimeout(timer);
      let timerId = setTimeout(() => {
        onSubmitSearch();
        setTimer(null);
      }, 400);

      setTimer(timerId);
    }
  }, [search]);

  useEffect(() => {
    document.querySelector(".header-search.show-results") &&
      document
        .querySelector(".header-search.show-results")
        .classList.remove("show-results");
  }, [router.pathname]);

  async function onSearchClick(e) {
    e.stopPropagation();

    if (pathname === "/search") {
      e.currentTarget.parentNode.classList.toggle("show");
    } else if (router.pathname === "/") {
      await router.push({
        pathname: "/",
        query: { search },
      });
    }
    const inputField = document.getElementById("search-input");
    if (inputField) {
      inputField.focus();
    }
  }
  function onSearchExpand(e) {
    e.currentTarget.parentNode.classList.toggle("show");
  }

  function onBodyClick(e) {
    if (e.target.closest(".header-search"))
      return (
        e.target.closest(".header-search").classList.contains("show-results") ||
        e.target.closest(".header-search").classList.add("show-results")
      );

    document.querySelector(".header-search.show") &&
      document.querySelector(".header-search.show").classList.remove("show");
    document.querySelector(".header-search.show-results") &&
      document
        .querySelector(".header-search.show-results")
        .classList.remove("show-results");
  }

  function onSearchChange(e) {
    setSearch(e.target.value);
  }

  function onSubmitSearchForm(e) {
    e.preventDefault();
    onSubmitSearch();
  }

  async function onSubmitSearch() {
    dispatch(eventActions.search(search));
    // document.querySelector(".header-search")?.classList.toggle("show");
    await router.push({
      pathname: "/search",
      query: { search },
    });

    document.querySelector(".header-search.show-results") &&
      document
        .querySelector(".header-search.show-results")
        .classList.remove("show-results");

    return false;
  }

  const handleType = () => {
    const i = loopNum % dataText.length;
    const fullText = dataText[i];

    setText((prevText) =>
      isDeleting
        ? fullText.substring(0, prevText.length - 1)
        : fullText.substring(0, prevText.length + 1)
    );

    setTypingSpeed(isDeleting ? DELETING_SPEED : TYPING_SPEED);

    if (!isDeleting && text === fullText) {
      setTimeout(() => setIsDeleting(true), 500);
    } else if (isDeleting && text === "") {
      setIsDeleting(false);
      setLoopNum((prevLoopNum) => prevLoopNum + 1);
    }
  };

  useEffect(() => {
    const typingInterval = setTimeout(handleType, typingSpeed);

    return () => clearTimeout(typingInterval);
  }, [text, isDeleting, loopNum, dataText, typingSpeed]);

  return (
    <div
      className={`header-search  ${
        type === "icon" ? "hs-toggle d-block" : "hs-simple"
      }`}
    >
      {type === "icon" && (
        <a
          href={pathname === "/search" ? "#" : "/search"}
          className="search-toggle"
          role="button"
          onClick={onSearchClick}
        >
          <Search size={24} />
        </a>
      )}
      <div className="input-wrapper">
        {pathname === "/search" ? (
          <input
            id="search-input"
            type="text"
            className="form-control"
            name="search"
            autoComplete="off"
            value={search}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                document
                  .querySelector(".header-search")
                  ?.classList.toggle("show");
                onSubmitSearchForm(e);
              }
            }}
            onChange={onSearchChange}
            placeholder={text}
            required
            autoFocus
          />
        ) : (
          <button
            className="form-control search-box-container"
            onClick={async () => {
              await router.push({
                pathname: "/search",
              });
            }}
          >
            <div id="search-input" type="text" name="search" required>
              <p className="m-0 font-size-16">Search for products...</p>
            </div>
          </button>
        )}

        <button
          className="btn btn-search"
          type="submit"
          aria-label="search"
          onClick={onSubmitSearchForm}
        >
          <MagnifyingGlass color="currentColor" size={20} />
        </button>
      </div>
    </div>
  );
}

export default connect(null, {
  productSearched: eventActions.productSearched,
})(React.memo(SearchForm));
