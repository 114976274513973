import { Analytics } from "@vercel/analytics/react";
import { useEffect } from "react";
import GTM from "react-gtm-module";

import Affise from "~/components/scripts/affise";
import Wisepops from "~/components/scripts/wisepops";
import { GTM_ID, WISEPOPS_KEY } from "~/config";
import { useIsInteractive } from "~/utils/contexts/navbar";

export default function Scripts() {
  const isInteractive = useIsInteractive();

  useEffect(() => {
    if (isInteractive) {
      GTM.initialize({ gtmId: GTM_ID });
    }
  }, [isInteractive]);

  return (
    <>
      <Analytics />
      <Affise />
      {!!isInteractive && <>{!!WISEPOPS_KEY && <Wisepops />}</>}
    </>
  );
}
